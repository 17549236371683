import React from 'react';
import './App.css';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Navbar from './components/Navbar';
import Clients from './components/Clients';
import Contact from './components/Contact';

function App() {
  return (
    // <p> Hello React </p>
    <div className="App">
        <Navbar />
         <Home
          title="Section 1"
          subtitle="Hello"
          dark={true}
          id="section1"
        />
         <Services
          title="Section 2"
          subtitle="Hello"
          dark={true}
          id="section2"
        />
         <About
          title="Section 3"
          subtitle="Hello"
          dark={false}
          id="section3"
        />
        <Clients
          title="Section 4"
          subtitle="Hello"
          dark={false}
          id="section4"
        />
        <Contact
          title="Section 5"
          subtitle="Hello"
          dark={false}
          id="section5"
        /> 
      </div>
 
  );
}

export default App;
