import React, { Component } from "react";
import Bank from ".././assets/images/s-bank.jpg";
import Automation from ".././assets/images/s-automation.jpg";
import Mobileapp from ".././assets/images/s-mobileapp.jpg";
import Devops from ".././assets/images/s-devops.jpg";

class Services extends Component {
  state = {};
  render() {
    const { dark, id } = this.props;
    return (
      <div className={dark ? " section-dark" : ""}>
        <div className="section-content" id={id}>
          <div className="section light-bg">
            <div className="container">
              <div className="section-title">
                <small> - Services - </small>
                <h3> What We Do </h3>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <img className="img-fluid" src={Bank} alt="Core Banking" />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <h5 className="services-head"> Core And Digital Banking </h5>
                  <p className="services-subhead">
                    We offer extensive experience in implementation and support
                    of Oracle Flexcube Core banking solution
                  </p>
                  <ul className="services-list">
                    <li> Product Walk through </li>
                    <li> Gap Analysis </li>
                    <li> Data Migration </li>
                    <li> Infrastructure Review and Installation </li>
                    <li>
                      Implementation of Solution Delivering Customized Solutions
                      alinging Business Strategy
                    </li>
                    <li> System Integration and User Acceptance Testing </li>
                    <li> Post production 24/7 support </li>
                  </ul>
                  <div>
                    Digital Banking, Our experience in digital banking
                    implementations and smooth integration with different core
                    banking platforms ensure seamless functioning of internet
                    and mobile banking.
                  </div>
                </div>
              </div>{/* Banking End */}
              <hr/>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 no-mobile-top">
                  <h5 className="services-head"> Automation Testing</h5>
                  <p className="services-subhead">
                  In automation testing, we perform following activities to release a qualitative product into the market
                  </p>
                  <ul className="services-list">
                    <li> Smoke testing where we perform basic level of testing to catch the defects which blocks the functionality of the product  </li>
                    <li> Sanity testing where we test the set of important functionalities of the product and Regression testing where we test the entire functionality of the product to make sure product is defect free </li>
                    <li> Application Testing  </li>
                    <li> Device Testing  </li>
                    <li> App Testing  </li>
                  </ul>
                  <div style={{ marginBottom: '20px' }}>
                  We have experts in developing the scripts for testing in languages 
                  like python, Perl,Java,PHP and We have expertise in using Robot 
                  framework for maintaining test suites and generating the reports.
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mobile-top">
                  <img className="img-fluid" src={Automation} alt="Automation" />
                </div>
              </div>{/* Automation End */}
              <hr/>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <img className="img-fluid" src={Mobileapp} alt="Mobile Apps" />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <h5 className="services-head"> App Development </h5>
                  <p className="services-subhead">
                  We offer scalable and interactive PWA, IOS and Andriod applications
                  </p>
                  <ul className="services-list">
                    <li> Desktop Apps  </li>
                    <li> Android / IOS Mobile Apps </li>
                    <li> Progressive Web Applications  </li>
                    <li> Existing Project Enhancement  </li>
                  </ul>
                  <div style={{ marginBottom: '20px' }}>
                    Our Native Mobile app development services encompass 
                    the full process, from product strategy to App Store submission.<br/>
                    <br/>
                    <b>Technology Stack</b>: Angular, React, React Native, Bootstrap, Html5, css3, Javascript, Rest Api, Python, Nodejs, Mongo DB
                  </div>
                </div>
              </div>{/* Mobileapp End */}
              <hr/>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 no-mobile-top">
                  <h5 className="services-head"> Infra And Devops </h5>
                  <p className="services-subhead">
                  We offer Infrastructure Design & Strategy, Cloud Migrations and 24×7 Managed Services
                  </p>
                  <ul className="services-list">
                    <li> Production Grade Infrastructure In AWS  </li>
                    <li> GCP And On-premise Following Proven Design Paradigms </li>
                    <li> Engineers can help cut your cloud costs by 20% or more  </li>
                    <li> 24/7 proactive and reactive managed support   </li>
                  </ul>
                  <div style={{ marginBottom: '20px' }}>
                  Our DevOps engineers help guide your transition to the cloud by 
                  architecting & implementing custom enterprise grade cloud solutions 
                  that are flexible, fully scalable & highly available. We also enable 
                  complete management & automated deployment of your apps through 
                  custom CI/CD pipeline development & configuration management tools
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mobile-top">
                  <img className="img-fluid" src={Devops} alt="Devops" />
                </div>
              </div>{/* Devops End */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
