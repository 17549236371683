import React, { Component } from 'react';
import ContactForm from './Contactform';

class Contact extends Component {
    state = {}
    render() {
        const { dark, id } = this.props;
        return (
            <div className={(dark ? " section-dark" : "")} style={{ paddingTop: '80px' }}>
                <div className="section-content" id={id}>
                    <div className="light-bg py-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 text-center">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="section-title">
                                                <small> - Info - </small>
                                                <h3> Contact Us  </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                    <div className="col-lg-6 text-center">
                                        <div className="test">
                                        <p className="mb-2"> <span className="ti-location-pin mr-2"></span>
                                            Mellontas IT Solutions LLP
                                    </p>
                                        <p className="mb-2">
                                            <span className="ti-email mr-2"></span> <a className="mr-4"
                                                href="mailto:contactus@mellontas.com"> Send Email </a>
                                        </p>
                                        <div className="social-icons">
                                            <a href="#"><span className="ti-facebook"></span></a>
                                            <a href="#"><span className="ti-twitter-alt"></span></a>
                                            <a href="#"><span className="ti-instagram"></span></a>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <ContactForm />
                                    </div>
                                </div>
                        </div>
                    </div>
                    <footer className="my-5 text-center">
                        <p className="mb-2"><small>COPYRIGHT © 2019. ALL RIGHTS RESERVED.</small></p>
                    </footer>
                </div>
            </div>
        );
    }
}

export default Contact;