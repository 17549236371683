import React, { Component } from "react";
import Client1 from "../assets/images/client-1.png";
import Client2 from "../assets/images/client-2.png";

class Clients extends Component {
  render() {
    const { dark, id } = this.props;

    return (
      <div className={"section" + (dark ? " section-dark" : "")}>
        <div className="section-content" id={id}>
          <div className="container">
            <div className="section-title">
              <small> - Working with - </small>
              <h3> Clients </h3>
            </div>
            <div style={{ marginTop: "250px" }}>
              <div id="clients-carousel">
                <div className="selected">
                  <img src={Client1} alt="Finexta" />
                </div>
                <div className="hideRight">
                  <img src={Client2} alt="Coming Soon" />
                </div>
                {/* <div className="hideLeft">
                                    <img src={ Client1 } alt="Finexta" />
                                </div>
                                <div className="prevLeftSecond">
                                    <img src={ Client2 } alt="Coming Soon" />
                                </div>
                                <div className="prev">
                                    <img src="https://i1.sndcdn.com/artworks-000158708482-k160g1-t500x500.jpg" />
                                </div>
                                <div className="selected">
                                    <img src="https://i1.sndcdn.com/artworks-000062423439-lf7ll2-t500x500.jpg" />
                                </div>
                                <div className="next">
                                    <img src="https://i1.sndcdn.com/artworks-000028787381-1vad7y-t500x500.jpg" />
                                </div>
                                <div className="nextRightSecond">
                                    <img src="https://i1.sndcdn.com/artworks-000108468163-dp0b6y-t500x500.jpg" />
                                </div>
                                <div className="hideRight">
                                    <img src="https://i1.sndcdn.com/artworks-000064920701-xrez5z-t500x500.jpg" />
                                </div> */}
              </div>
              <div className="buttons">
                <button
                  id="prev"
                  type="button"
                  className="btn btn-outline-primary btn-sm"
                >
                  {" "}
                  Previous{" "}
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <button
                  id="next"
                  type="button"
                  className="btn btn-outline-primary btn-sm"
                >
                  {" "}
                  Next{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Clients;
