import React, { Component } from 'react';

class About extends Component {
    state = {}
    render() {
        const { dark, id } = this.props;
        return (
            <div className={(dark ? " section-dark" : "")}>
                <div className="section-content" id={id}>
                    <div className="section-about bg-gradient-about">
                        <div className="container">
                            <div className="section-title">
                                <small style={{ color: '#fff' }}> - Our Mission - </small>
                                <h3 style={{ color: '#fff' }}> ABOUT US </h3>
                            </div>
                            <div className="about-content" style={{
                                fontSize: '18px',
                                fontWeight: 300, color: '#ffffff'
                            }}>
                                <div style={{ fontWeight: 'bold', textAlign: 'center', color: '#ff1481' }}>
                                    Mellontas <br/>
                                    <small> We deliver what you require </small>
                                </div>
                                <div style={{ marginTop: '20px', lineHeight:'28px' }}>
                                    At Mellontas, We believe passion transcends technology.
                                    Our mission is to deliver high quality solutions to simplify
                                    and transform your business in this digital age and 
                                    We offer resource augmentation along with solution delivery
                                    in banking, fintech, telecommunication, health care and retail
                                    industries.our team comprises of experts in latest technologies
                                    with working experience in leading MNCs.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;