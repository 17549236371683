import React, { Component } from 'react';
import { Link, animateScroll as scroll } from "react-scroll";

class Home extends Component {
    state = {}
    render() {
        const { dark, id } = this.props;
        return (
            <div className={(dark ? " section-dark" : "")}>
                <div className="section-content" id={id}>
                    <section className="content-section video-section">
                        <div className="pattern-overlay">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h1> Mellontas </h1>
                                        <h3> Passion Transcends Technology </h3>
                                        <div style={{ textAlign: 'center' }}>
                                        <div style={{
                                            width: '100px',
                                            fontSize: '50px',
                                            color: '#fff',
                                            paddingTop: '50px', 
                                            cursor: 'pointer',
                                            margin: '0 auto'
                                        }}>
                                            <Link className="nav-link" to="section2" spy={true}
                                                smooth={true} offset={-70} duration={500}>
                                                <span className="ti-angle-double-down"></span>
                                            </Link>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default Home;