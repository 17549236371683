import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';



ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

$(document).ready(function(){
    function moveToSelected(element) {

        if (element == "next") {
          var selected = $(".selected").next();
        } else if (element == "prev") {
          var selected = $(".selected").prev();
        } else {
          var selected = element;
        }
      
        var next = $(selected).next();
        var prev = $(selected).prev();
        var prevSecond = $(prev).prev();
        var nextSecond = $(next).next();
      
        $(selected).removeClass().addClass("selected");
      
        $(prev).removeClass().addClass("prev");
        $(next).removeClass().addClass("next");
      
        $(nextSecond).removeClass().addClass("nextRightSecond");
        $(prevSecond).removeClass().addClass("prevLeftSecond");
      
        $(nextSecond).nextAll().removeClass().addClass('hideRight');
        $(prevSecond).prevAll().removeClass().addClass('hideLeft');
      
      }
      
      // Eventos teclado
      $(document).keydown(function(e) {
          switch(e.which) {
              case 37: // left
              moveToSelected('prev');
              break;
      
              case 39: // right
              moveToSelected('next');
              break;
      
              default: return;
          }
          e.preventDefault();
      });
      
      $('#clients-carousel div').click(function() {
        moveToSelected($(this));
      });
      
      $('#prev').click(function() {
        moveToSelected('prev');
      });
      
      $('#next').click(function() {
        moveToSelected('next');
      });

      /*-----------------------------------
     * FIXED  MENU - HEADER
     *-----------------------------------*/
    function menuscroll() {
      var $navmenu = $('.nav-menu');
      if ($(window).scrollTop() > 50) {
          $navmenu.addClass('is-scrolling');
      } else {
          $navmenu.removeClass("is-scrolling");
      }
  }
  menuscroll();
  $(window).on('scroll', function() {
      menuscroll();
  });
  /*-----------------------------------
   * NAVBAR CLOSE ON CLICK
   *-----------------------------------*/

  $('.navbar-nav > li:not(.dropdown) > a').on('click', function() {
      $('.navbar-collapse').collapse('hide');
  });
  /* 
   * NAVBAR TOGGLE BG
   *-----------------*/
  var siteNav = $('#navbar');
  siteNav.on('show.bs.collapse', function(e) {
      $(this).parents('.nav-menu').addClass('menu-is-open');
  })
  siteNav.on('hide.bs.collapse', function(e) {
      $(this).parents('.nav-menu').removeClass('menu-is-open');
  });

})
