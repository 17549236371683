import React, { Component } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

class ContactForm extends Component {
  state = {}
  render() {
    const phoneRegExp = /^\d{10}$/;

    return (
      <Formik
        initialValues={{
          fullName: '',
          email: '',
          mobileNumber: '',
          message: ''
        }}
        validationSchema={Yup.object().shape({
          fullName: Yup.string()
            .required('Fullname is required'),
          email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
          mobileNumber: Yup.string()
            .matches(phoneRegExp, 'Phone number is not valid')
            .required('Mobile Number is required'),
          message: Yup.string()
            .min(10, 'Message must be at least 10 characters')
            .required('Message is required'),
        })}
        onSubmit={(fields, actions) => {
            actions.resetForm();
        }}
        render={({ errors, status, touched }) => (
          <div className="container">
            <Form action=".././php/contact.php" method="post">
              <div className="form-group">
                <label htmlFor="fullName">Full Name</label>
                <Field id="name" name="fullName" type="text" className={'form-control' + (errors.fullName && touched.fullName ? ' is-invalid' : '')} />
                <ErrorMessage name="fullName" component="div" className="invalid-feedback" />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Field id="email" name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                <ErrorMessage name="email" component="div" className="invalid-feedback" />
              </div>
              <div className="form-group">
                <label htmlFor="mobileNumber"> Mobile Number </label>
                <Field id="mobile" name="mobileNumber" type="number" className={'form-control' + (errors.mobileNumber && touched.mobileNumber ? ' is-invalid' : '')} />
                <ErrorMessage name="mobileNumber" component="div" className="invalid-feedback" />
              </div>
              <div className="form-group">
                <label htmlFor="message"> Message </label>
                <Field id="message" name="message" component="textarea" className={'form-control' + (errors.message && touched.message ? ' is-invalid' : '')} />
                <ErrorMessage name="message" component="div" className="invalid-feedback" />
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-primary mr-2"> Shoot A Message </button>
              </div>
            </Form>
          </div>
        )}
      />
    );
  }
}

export default ContactForm;